var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
    },
    [
      _c("v-server-table", {
        ref: "emailsTable",
        staticClass: "detail-table",
        attrs: {
          name: "emailsTable",
          columns: _vm.columns,
          options: _vm.tableOptions,
        },
        scopedSlots: _vm._u([
          {
            key: "NumberRow",
            fn: function (list) {
              return [_vm._v(_vm._s(list.index) + ".")]
            },
          },
          {
            key: "PeslogDatetime",
            fn: function (list) {
              return [
                _vm._v(_vm._s(_vm._f("dateTime")(list.row.PeslogDatetime))),
              ]
            },
          },
          {
            key: "Edit",
            fn: function (list) {
              return [
                _c("div", { staticClass: "d-flex justify-content-end" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.left",
                          modifiers: { hover: true, left: true },
                        },
                      ],
                      attrs: { title: _vm.$t("previewsente-ma") },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "detail-table-icon",
                        attrs: { icon: "eye", cursor: "pointer", size: "lg" },
                        on: {
                          click: function ($event) {
                            return _vm.actionsClick(list.row)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("email-send-modal", {
        attrs: {
          isVisible: this.showEmailSend,
          typeId: this.modalTypeId,
          sendLogEmailId: this.modalSendLogEmailId,
          templateId: this.modalTemplateId,
          entityId: this.modalEntityId,
          isSentEmail: true,
        },
        on: { onClose: _vm.closeEmailSend },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }