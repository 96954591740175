import i18n from '../../services/lang';

const service = {
	headingTranslations() {
		return {
			NumberRow: i18n.t('s.no.'),
			PeslogDatetime: i18n.t('sent'),
			PeslogSubject: i18n.t('subject'),
			PeslogRecipients: i18n.t('to2339'),
			UserName: i18n.t('from'),
			Edit: i18n.t('actions'),
		};
	},
	formTranslations(schema) {},
};

export default service;
