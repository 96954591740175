import vueFormGenerator from 'vue-form-generator';
import i18n from '../../services/lang';
import enums from '../../services/helpers/enums';

var model = {
	modal: {
		Sender: null,
		SenderDisplayName: null,
		Recipients: null,
		Subject: null,
		Body: null,
		EntityId: null,
		TemplateId: null,
		TypeId: null,
		AttachInvoicePdf: null,
	},
	table: {
		showEmailSend: false,
		sendLogEmailId: 0,
		controllerName: 'PortalEmailSendLogListItem',
		columns: ['NumberRow', 'PeslogDatetime', 'PeslogSubject', 'PeslogRecipients', 'UserName', 'Edit'],
		modalTypeId: 0,
		modalSendLogEmailId: 0,
		modalTemplateId: 0,
		modalEntityId: 0,
	},
	fields: {
		groups: [
			{
				fields: [
					{
						type: 'input',
						inputType: 'text',
						label: i18n.t('from:'),
						model: 'Sender',
						required: true,
					},
					{
						type: 'input',
						inputType: 'text',
						label: i18n.t('to:'),
						model: 'Recipients',
						required: true,
						validator: vueFormGenerator.validators.string,
					},
				],
			},
			{
				styleClasses: 'col-xs-12 inline-input-group-wrapper',
				fields: [
					{
						type: 'input',
						inputType: 'text',
						label: i18n.t('subject:'),
						model: 'Subject',
						required: true,
						styleClasses: 'col-xs-12 first-input',
						validator: vueFormGenerator.validators.string,
					},
					{
						type: 'checkboxes-group',
						label: '',
						model: 'AttachInvoicePdf',
						values: [{ value: true, name: i18n.t('sendpdfinvoicef') }],
						styleClasses: 'col-xs-12 second-input',
						visible: function(model) {
							return model.TypeId == enums.sendLogsTypeEnum.Invoice;
						},
					},
				],
			},
		],
	},
};

export default model;
