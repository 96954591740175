<template>
	<div v-show="show">
		<v-server-table name="emailsTable" :columns="columns" :options="tableOptions" ref="emailsTable" class="detail-table">
			<template #NumberRow="list">{{ list.index }}.</template>

			<template #PeslogDatetime="list">{{ list.row.PeslogDatetime | dateTime }}</template>

			<template #Edit="list">
				<div class="d-flex justify-content-end">
					<div v-b-tooltip.hover.left :title="$t('previewsente-ma')">
						<font-awesome-icon
							icon="eye"
							cursor="pointer"
							class="detail-table-icon"
							size="lg"
							@click="actionsClick(list.row)"
						></font-awesome-icon>
					</div>
				</div>
			</template>
		</v-server-table>
		<email-send-modal
			:isVisible="this.showEmailSend"
			:typeId="this.modalTypeId"
			:sendLogEmailId="this.modalSendLogEmailId"
			:templateId="this.modalTemplateId"
			:entityId="this.modalEntityId"
			:isSentEmail="true"
			@onClose="closeEmailSend"
		/>
	</div>
</template>

<script>
import { debounce } from 'debounce';
import { Promise } from 'q';
import { abstractField } from 'vue-form-generator';

import serviceCommon from '../../services/service/common.service';
import vars from '../../services/helpers/variables';

import service from './email-send.service';
import model from './email-send.model';

export default {
	components: {
		'email-send-modal': () => import('./email-send.modal'),
	},
	mixins: [abstractField],
	props: {
		entityId: { type: Number },
		entityType: { type: Number },
		show: { type: Boolean, default: false },
	},
	data() {
		return model.table;
	},
	methods: {
		closeEmailSend(getData) {
			this.showEmailSend = false;
			if (getData) {
				this.$refs.emailsTable.getData();
			}
		},
		actionsClick(row) {
			this.modalTypeId = row.PeslogType;
			this.modalSendLogEmailId = row.PeslogId;
			this.modalTemplateId = row.PemailId;
			this.modalEntityId = row.PeslogHiddenId;
			this.showEmailSend = true;
		},
	},
	computed: {
		tableOptions() {
			return {
				preserveState: true,
				orderBy: { column: 'PeslogId', ascending: 0 },
				filterByColumn: true,
				columns: 'Cols',
				pagination: vars.tableVars.paginationSetting,
				sortIcon: vars.tableVars.sortIcons,
				sortable: ['PeslogDatetime', 'PeslogSubject', 'PeslogRecipients', 'Sender'],
				filterable: [],
				texts: serviceCommon.recordsPaginationsTexts(),
				perPage: 10,
				headings: service.headingTranslations(),
				requestFunction: (data) => {
					data['filter'] = {
						PeslogHiddenId: this.entityId,
						PeslogType: this.entityType,
					};

					var info = {
						data: data,
						controller: this.controllerName,
					};

					return serviceCommon.getListForTable(info);
				},
			};
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/components/detail-table.scss';
</style>
